

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutProductImageCarouselV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

selectProduct(productId, variantId) {
    const changedProduct = productId != this.currentProduct?.id
    if (changedProduct) {
      this.currentVariantId = variantId
      this.currentProduct = globalResourceData.productsById[productId]
      
      this.render()
      this.calculateTotalThumbnails()
      this.mountSlider()
      this.scrollToVariant(true)
    }

    const changedVariant = variantId != this.currentVariantId
    
    if(changedVariant) {
      this.currentVariantId = variantId
      this.scrollToVariant(changedProduct)
    }
  }


  initialize() {
    document.addEventListener('ProductCarousel:Update', (evt) => {
      const { productId, variantId } = evt.detail
      this.selectProduct(productId, variantId)
    })
  }

  calculateTotalThumbnails () {
    this.totalImages = this.element.querySelectorAll(".slider-nav .swiper-slide").length
  }

  scrollToVariant(disableAnimation = false) {
    const variantImages = this.element.querySelectorAll(`.slider-nav [data-variant-id="${this.currentVariantId}"]`)
    const productImages = this.element.querySelectorAll(".slider-nav .swiper-slide:not([data-variant-id])")
    const itemImages = variantImages.length ? variantImages : productImages
    if (itemImages.length) {
      const itemPositions = [...itemImages].map((item) => item.getAttribute('data-image-position'))
      const firstPosition = itemPositions[0]
      const lastPosition = itemPositions[itemPositions.length -1]
      const speed = disableAnimation ? 0 : undefined
      if (this.swiperNav) {
        this.swiperNav.setProgress(firstPosition / (this.totalImages - this.numberOfThumbnails), speed)
      }
      this.swiper.slideTo(firstPosition, speed)
    }
  }

  mountSlider () {
    const mainSliderEl = this.element.querySelector('.slider-main')
    const navSliderEl = this.element.querySelector('.slider-nav')
    const hasThumbnails = !!this.element.querySelector('.slider-nav')
    const hasNextPrevButtons = !!this.element.querySelector('.swiper-button-next, .swiper-button-prev')
    this.swiperNav = null
    if (hasThumbnails) {
      this.swiperNav = new Swiper(navSliderEl, {
        spaceBetween: this.spaceBetweenThumbnails,
        slidesPerView: this.numberOfThumbnails,
        freeMode: true,
        watchSlidesProgress: true,
        mousewheel: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        }
      });
    }
    this.swiper = new Swiper(mainSliderEl, {
      spaceBetween: 1,
      loop: true,
      ...(hasNextPrevButtons ? {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      }: {}),
      ...(hasThumbnails ? {
        thumbs: {
          swiper: this.swiperNav,
        },
      } : {})
    });
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const currentProduct = this.currentProduct ?? null ;
const numberOfThumbnails = this.numberOfThumbnails ?? null ;
const spaceBetweenThumbnails = this.spaceBetweenThumbnails ?? null ;

    const showThumbnails = this.element.getAttribute('data-param-showThumbnails');
const linkedCheckoutId = this.element.getAttribute('data-param-linkedCheckoutId');
  
      let html = '';
      {
        let assignedProduct = currentProduct;
        html += `<div class="elProductImageCarouselOuterFrame"></div>`
        const is_editor = false;
        let warning = "";
        if (!assignedProduct) {
          assignedProduct = globalResourceData.productsWithNoBump[0];
        }
        let imagePosition = 0;
        const variantImages = assignedProduct.variants.map((i) => i.images).flat();
        const allImages = assignedProduct.images.concat(variantImages).filter((v) => v != null && v != undefined);
        const totalImages = allImages.length ?? 0;
        html += `<div style="--widget-tooltip-message-bottom:0px;--number-of-thumbnails:${numberOfThumbnails};--space-between-thumbnails:${spaceBetweenThumbnails};--total-images:${totalImages};" class="elCheckoutProductImageCarouselContainer" data-widget-tooltip-message="">`
        if (totalImages > 0) {
          html += `<div class="slider-main-wrapper"><div class="elProductCarouselMainImageOuterFrame"></div><div class="swiper slider-main"><div class="swiper-wrapper">`
          imagePosition = 0;
          if (assignedProduct.images.length > 0) {
            const c0 = assignedProduct.images
            const fl1 = new CF2ForloopDrop(c0.length)
            for (const imageUrl of c0) {
              const forloop = fl1
              html += `<div class="swiper-slide`
              if (imagePosition == 0 && "main" == "nav") {
                html += ` swiper-slide-thumb-active`
              }
              html += `" data-image-position="${imagePosition}"><img src="${imageUrl}"/></div>`
              imagePosition = imagePosition+ 1;
              forloop.next();
            }
          }
          const c2 = assignedProduct.variants
          const fl3 = new CF2ForloopDrop(c2.length)
          for (const variant of c2) {
            const forloop = fl3
            if (variant.images.length > 0) {
              const c4 = variant.images
              const fl5 = new CF2ForloopDrop(c4.length)
              for (const imageUrl of c4) {
                const forloop = fl5
                html += `<div class="swiper-slide`
                if (imagePosition == 0 && "main" == "nav") {
                  html += ` swiper-slide-thumb-active`
                }
                html += `" data-image-position="${imagePosition}" data-variant-id="${variant.id}"><img src="${imageUrl}"/></div>`
                imagePosition = imagePosition+ 1;
                forloop.next();
              }
            }
            forloop.next();
          }
          html += `</div>`
          if (totalImages > 1) {
            html += `<div class="swiper-button-next"></div>`
            html += `<div class="swiper-button-prev"></div>`
          }
          html += `</div></div>`
          if (showThumbnails && totalImages > 1) {
            html += `<div class="slider-nav-wrapper"><div class="swiper slider-nav"><div class="swiper-wrapper">`
            imagePosition = 0;
            if (assignedProduct.images.length > 0) {
              const c6 = assignedProduct.images
              const fl7 = new CF2ForloopDrop(c6.length)
              for (const imageUrl of c6) {
                const forloop = fl7
                html += `<div class="swiper-slide`
                if (imagePosition == 0 && "nav" == "nav") {
                  html += ` swiper-slide-thumb-active`
                }
                html += `" data-image-position="${imagePosition}"><img src="${imageUrl}"/></div>`
                imagePosition = imagePosition+ 1;
                forloop.next();
              }
            }
            const c8 = assignedProduct.variants
            const fl9 = new CF2ForloopDrop(c8.length)
            for (const variant of c8) {
              const forloop = fl9
              if (variant.images.length > 0) {
                const c10 = variant.images
                const fl11 = new CF2ForloopDrop(c10.length)
                for (const imageUrl of c10) {
                  const forloop = fl11
                  html += `<div class="swiper-slide`
                  if (imagePosition == 0 && "nav" == "nav") {
                    html += ` swiper-slide-thumb-active`
                  }
                  html += `" data-image-position="${imagePosition}" data-variant-id="${variant.id}"><img src="${imageUrl}"/></div>`
                  imagePosition = imagePosition+ 1;
                  forloop.next();
                }
              }
              forloop.next();
            }
            html += `</div></div><div class="swiper-scrollbar swiper-scrollbar-horizontal"></div></div>`
          }
        }
        html += `</div>`

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('CheckoutProductImageCarousel/V1', CheckoutProductImageCarouselV1)
window["CheckoutProductImageCarouselV1"] = CheckoutProductImageCarouselV1

